<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="site-tab">
        <div class="site-tab-l">
          <p :class="tabType == -1 ? 'active' : ''" @click="changeTab(-1)">全部组织</p>
          <p :class="tabType == index ? 'active' : ''" v-for="(item,index) in typesObj" :key="index" @click="changeTab(index)">{{ item }}</p>
<!--          <p>企业机构</p>-->
<!--          <p>渠道</p>-->
        </div>
        <div class="site-tab-r">
            <Button type="primary" style="" @click="add">{{$t('site_add')}}</Button>
        </div>
      </div>
      <div class="margin-bottom-10 top">
<!--        <div>-->
<!--          <Button type="primary" style="margin-right: 10px;" @click="add">{{$t('site_add')}}</Button>-->
<!--          <Button type="info" @click="toInvitation">{{$t('site_invitation')}}</Button>-->
<!--        </div>-->

      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            筛选:
          </div>
          <div class="cont">
            <Checkbox v-model="isValid" @on-change="changeKeyword">
              <span style="font-size: 14px">有效组织</span>
            </Checkbox>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            最近新增成员:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curRecentlyAddedTypeId == item.value ? 'active' : ''" v-for="(item,index) in recentlyAddedTypes" :key="index + 'category'" @click="changeSearch(item.value,'curRecentlyAddedTypeId')">{{item.label}}</div>
            </div>
          </div>
        </div>
<!--        <div class="search-item">-->
<!--          <div class="name">-->
<!--            活跃度排序:-->
<!--          </div>-->
<!--          <div class="cont">-->
<!--            <div class="values">-->
<!--              <div :class="activation == item.value ? 'active' : ''" v-for="(item,index) in activationList" :key="index + 'activation'" @click="changeSearch(item.value,'activation')">{{item.label}}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="search-item">
          <div class="name">
            名称搜索:
          </div>
          <div class="cont">
            <div class="search_input">
<!--              <Input v-model="keyword"-->
<!--                     @on-enter="changeKeyword"-->
<!--                     @on-blur="changeKeyword" placeholder="输入名称搜索"-->
<!--                     clearable-->
<!--                     size="large"-->
<!--                     :enter-button="true"-->
<!--              />-->
              <el-input v-model="keyword" clearable placeholder="输入名称搜索" @change="changeKeyword"></el-input>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            地域筛选:
          </div>
          <div class="cont">
            <el-cascader :options="cityList" v-model="cityId" :props="optionProps" clearable @change="changeKeyword"></el-cascader>
<!--            <div class="values">-->
<!--              <div :class="curRecentlyAddedTypeId == item.value ? 'active' : ''" v-for="(item,index) in recentlyAddedTypes" :key="index + 'category'" @click="changeSearch(item.value,'curRecentlyAddedTypeId')">{{item.label}}</div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="search-item" v-if="tabType == 0">
          <div class="name">
            覆盖:
          </div>
          <div class="cont">
            <Select filterable v-model="cover" size="large" clearable @on-change="changeKeyword">
              <Option :value="item.value" v-for="item in coverList" :key="item.value + item.label">{{ item.label }}</Option>
            </Select>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            院校等级:
          </div>
          <div class="cont">
            <!-- <el-input v-model="level" clearable placeholder="输入院校等级搜索" @change="changeKeyword"></el-input> -->
            <el-select v-model="level" clearable placeholder="请选择院校等级搜索" @change="changeKeyword">
              <el-option
                v-for="item in levelsArr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <Button type="primary" style="margin-bottom: 15px" @click="reset">重置</Button>
      </div>
      <Table border :columns="columns" :loading="loading" :data="data" @on-sort-change="sortChange"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="deleText" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <Modal
          v-model="modalStatus"
          :title="modalTit"
          @on-cancel="cancel"
      >
        <el-form :model="formItem" ref="formCustom" label-width="80px" :rules="ruleValidate">
          <el-form-item :label="$t('site_table_name')" prop="name">
            <el-input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <!--        <FormItem label="租户状态" prop="status">-->
          <!--          <Select v-model="formItem.status" style="width:100%" placeholder="租户状态">-->
          <!--            <Option v-for="item in statusList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
          <!--          </Select>-->
          <!--        </FormItem>-->
<!--          <el-form-item :label="$t('site_table_desc')" prop="desc">-->
<!--            <el-input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('site_add_desc_tip')"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="formItem.type">
              <el-radio :label="index" v-for="(item,index) in typesObj" :key="index">{{ item }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="地域" prop="cityId">
            <el-cascader :options="cityList" v-model="formItem.cityId" :props="modalOptionProps" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="二级域名" prop="self_domain">
            <el-input :disabled="type == 'check'" v-model="formItem.self_domain" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="门户名称" prop="full_name">
            <el-input :disabled="type == 'check'" v-model="formItem.full_name" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="intro">
            <el-input :disabled="type == 'check'" v-model="formItem.intro" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="导航小LOGO" prop="logo">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       accept=".jpg,.png,.jpeg,.gif"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadLogoSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.logo">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <img :src="formItem.logo" height="60"  />
              </div>

            </el-upload>
          </el-form-item>
          <el-form-item label="门户长LOGO" prop="logo">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       accept=".jpg,.png,.jpeg,.gif"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadFullLogoSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.fullLogo">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <img :src="formItem.fullLogo" height="60"  />
              </div>

            </el-upload>
          </el-form-item>
          <el-form-item label="门户大背景" prop="background_img">
<!--            <el-upload-->
<!--                :action="constant.URL + '/uploadfile/upload'"-->
<!--                :data="{-->
<!--                      type:3-->
<!--                    }"-->
<!--                accept=".jpg,.png,.jpeg,.gif"-->
<!--                :headers="{-->
<!--                      'access-token':Cookies.get('liteAccessToken')-->
<!--                    }"-->
<!--                :on-success="uploadBackgroundSuccess"-->
<!--                :show-file-list="false"-->
<!--            >-->
<!--              <Button size="small" type="primary">点击上传</Button>-->
<!--              <div slot="tip" class="el-upload__tip">上传尺寸：宽1920px*高1080px</div>-->
<!--            </el-upload>-->
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       accept=".jpg,.png,.jpeg,.gif"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadBackgroundSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.background_img">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <img :src="formItem.background_img" width="300"   />
              </div>
              <div slot="tip" class="el-upload__tip">上传尺寸：宽1920px*高1080px</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <Button @click="cancel">取消</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>
        </div>
      </Modal>
    </div>

    <el-drawer :visible.sync='remarkModal' :size="800">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>跟进（{{ curData.name }}）</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <div>
          <div class="form-item-label">备注内容：</div>
          <Input v-model="content" :rows="3" type="textarea" placeholder="请输入备注内容..."  />
          <div class="form-item-label" style="margin-top: 20px; margin-bottom: 0;">联系老师：</div>
          <el-select
              v-model="userId"
              filterable
              remote
              :remote-method="remoteMethod"
              :loading="userLoading"
              clearable
              class="mt20"
          >
            <el-option
                v-for="ssItem in userList"
                :key="ssItem.id"
                :label="ssItem.nickname + '('+ssItem.id+')'"
                :value="ssItem.id">
            </el-option>
          </el-select>
          <div class="top-btn">
            <Button type="primary" class="mt20" @click="confirmRemark">确定</Button>
          </div>
        </div>
        <div class="history">
          <p class="history-tit">历史备注：</p>
          <div class="history-list">
            <div style="position: relative;width: 100%;height: 100px" v-if="remarkLoading">
              <Spin fix></Spin>
            </div>
            <Timeline>
              <TimelineItem v-for="item in remarkList" :key="item.id">
                <p class="time">{{ item.created_at }} <span v-if="item.user">|</span><span v-if="item.user">跟进人：{{ item?.user?.nickname }}（{{ item?.user?.id }}）</span><span>|</span>创建人：{{ item?.creator?.nickname }}（{{item?.creator?.id}}）</p>
                <div class="content-wrap">
                  <p class="content">{{item.content}}</p>
                  <div>
                    <el-button type="warning" size="mini" @click="editRemark(item)">编辑</el-button>
                    <el-button type="danger" size="mini" @click="deleteRemark(item)">删除</el-button>
                  </div>
                </div>
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-drawer :visible.sync='editRemarkshow' :size="600" @close="editRemarkClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>编辑跟进记录</div>
        <div class='drawer-title-btns'>
          <el-button type="primary" size="small" @click="editRemarkOk()">确定</el-button>
        </div>
      </div>
      <div style='padding:20px;'>
        <div class="form-item-label">备注内容：</div>
          <Input v-model="editRemarkForm.content" :rows="3" type="textarea" placeholder="请输入备注内容..."  />
          <div class="form-item-label" style="margin-top: 20px; margin-bottom: 0;">联系老师：</div>
          <el-select
              v-model="editRemarkForm.followup_user_id"
              filterable
              remote
              :remote-method="remoteMethod"
              :loading="userLoading"
              clearable
              class="mt20"
          >
            <el-option
                v-for="ssItem in userList2"
                :key="ssItem.id"
                :label="ssItem.nickname + '('+ssItem.id+')'"
                :value="ssItem.id">
            </el-option>
          </el-select>
      </div>
    </el-drawer>

    <!-- 组织编辑抽屉 -->
    <el-drawer
      :title="modalTit"
      :visible.sync="editDrawer"
      :modal="true"
      direction="rtl"
      :size="800"
      >
        <div class="drawer-title" slot="title">
          <div class="drawer-title-text">{{ modalTit }}</div>
          <div class="drawer-title-btns">
            <Button class="cancel" style="margin-right:10px;" @click="cancel">取消</Button>
            <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>
          </div>
        </div>
        <div style="padding: 20px;">
          <el-form :model="formItem" ref="formCustom" label-width="130px" :rules="ruleValidate">
          <el-form-item :label="$t('site_table_name')" prop="name">
            <el-input :disabled="type == 'check'" v-model="formItem.name" maxlength="20" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-radio-group v-model="formItem.type">
              <el-radio :label="index" v-for="(item,index) in typesObj" :key="index">{{ item }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="服务类型">
            <el-radio-group v-model="formItem.service_type">
              <el-radio v-for="item in service_typesArr" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="地域" prop="cityId">
            <el-cascader :options="cityList" v-model="formItem.cityId" :props="modalOptionProps" clearable></el-cascader>
          </el-form-item>
            <el-radio-group v-model="setType" style="margin-bottom: 30px">
              <el-radio-button label="0">门户配置</el-radio-button>
              <el-radio-button label="1">AI问答配置</el-radio-button>
              <el-radio-button label="2">题库配置</el-radio-button>
            </el-radio-group>
<!--          门户配置-->
          <el-form-item label="二级域名" prop="self_domain" v-if="setType == 0">
            <el-input :disabled="type == 'check'" v-model="formItem.self_domain" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="门户名称" prop="full_name" v-if="setType == 0">
            <el-input :disabled="type == 'check'" v-model="formItem.full_name" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="intro" v-if="setType == 0">
            <el-input :disabled="type == 'check'" type="textarea" v-model="formItem.intro" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="导航小LOGO" prop="logo" v-if="setType == 0">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       accept=".jpg,.png,.jpeg,.gif,.svg"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadLogoSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.logo">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <img :src="formItem.logo" height="60"  />
              </div>

            </el-upload>
          </el-form-item>
          <el-form-item label="门户长LOGO" prop="logo" v-if="setType == 0">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       accept=".jpg,.png,.jpeg,.gif,.svg"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadFullLogoSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.fullLogo">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img full-logo"  v-else>
                <img :src="formItem.fullLogo" height="60"  />
                <div class="full-logo-dele">
                  <i class="el-icon-delete" @click.stop="deleFullLogo"></i>
                </div>
              </div>

            </el-upload>
          </el-form-item>
          <el-form-item label="门户大背景" prop="background_img" v-if="setType == 0">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                      type:3
                    }"
                       accept=".jpg,.png,.jpeg,.gif,.svg"
                       :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                       :on-success="uploadBackgroundSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.background_img">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <img :src="formItem.background_img" width="300"   />
              </div>
              <div slot="tip" class="el-upload__tip">上传尺寸：宽1920px*高1080px</div>
            </el-upload>
          </el-form-item>
<!--            AI配置-->
          <el-form-item label="二级域名" prop="ai_domain" v-if="setType == 1">
            <el-input :disabled="type == 'check'" v-model="formItem.ai_domain" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
            <el-form-item label="LOGO" prop="ai_logo" v-if="setType == 1">
              <el-upload :action="constant.URL + '/uploadfile/upload'"
                         :data="{
                    type:3
                  }"
                         accept=".jpg,.png,.jpeg,.gif,.svg"
                         :headers="{
                    'access-token':Cookies.get(constant.tokenName)
                  }"
                         :on-success="uploadAiLogoSuccess"
                         :show-file-list="false"
              >
                <div class="upload-btn" v-if="!formItem.ai_logo">
                  <Icon type="ios-add" :size="50" />
                </div>
                <div class="dingding-img full-logo"  v-else>
                  <img :src="formItem.ai_logo" height="60"  />
                  <div class="full-logo-dele">
                    <i class="el-icon-delete" @click.stop="deleFullLogo"></i>
                  </div>
                </div>

              </el-upload>
            </el-form-item>
          <el-form-item label="标题" prop="ai_title" v-if="setType == 1">
            <el-input :disabled="type == 'check'" v-model="formItem.ai_title" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="ai_intro" v-if="setType == 1">
            <el-input :disabled="type == 'check'" type="textarea" v-model="formItem.ai_intro" :placeholder="$t('site_add_name_tip')"></el-input>
          </el-form-item>
          <el-form-item label="回答头像" prop="ai_dialog_logo" v-if="setType == 1">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                    type:3
                  }"
                       accept=".jpg,.png,.jpeg,.gif,.svg"
                       :headers="{
                    'access-token':Cookies.get(constant.tokenName)
                  }"
                       :on-success="uploadAiDialogLogoSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.ai_dialog_logo">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <img :src="formItem.ai_dialog_logo" height="60"  />
              </div>

            </el-upload>
          </el-form-item>
            <el-form-item label="标签栏logo" prop="ai_icon_logo" v-if="setType == 1">
              <el-upload :action="constant.URL + '/uploadfile/upload'"
                         :data="{
                    type:3
                  }"
                         accept=".jpg,.png,.jpeg,.gif,.svg"
                         :headers="{
                    'access-token':Cookies.get(constant.tokenName)
                  }"
                         :on-success="uploadAiIconLogoSuccess"
                         :show-file-list="false"
              >
                <div class="upload-btn" v-if="!formItem.ai_icon_logo">
                  <Icon type="ios-add" :size="50" />
                </div>
                <div class="dingding-img" v-else>
                  <img :src="formItem.ai_icon_logo" height="60"  />
                </div>

              </el-upload>
            </el-form-item>

          <el-form-item label="门户视频背景" prop="ai_bg_video" v-if="setType == 1">
            <el-upload :action="constant.URL + '/uploadfile/upload'"
                       :data="{
                    type:3
                  }"
                       accept=".mp4,.avi"
                       :headers="{
                    'access-token':Cookies.get(constant.tokenName)
                  }"
                       :on-success="uploadAiBgVideoSuccess"
                       :show-file-list="false"
            >
              <div class="upload-btn" v-if="!formItem.ai_bg_video">
                <Icon type="ios-add" :size="50" />
              </div>
              <div class="dingding-img" v-else>
                <video :src="formItem.ai_bg_video" controls width="300" />
              </div>
              <div slot="tip" class="el-upload__tip">上传尺寸：宽1920px*高1080px</div>
            </el-upload>
          </el-form-item>
            <el-form-item label="门户图片背景" prop="ai_bg_img" v-if="setType == 1">
              <el-upload :action="constant.URL + '/uploadfile/upload'"
                         :data="{
                    type:3
                  }"
                         accept=".jpg,.png,.jpeg,.gif,.svg"
                         :headers="{
                    'access-token':Cookies.get(constant.tokenName)
                  }"
                         :on-success="uploadAiBgImgSuccess"
                         :show-file-list="false"
              >
                <div class="upload-btn" v-if="!formItem.ai_bg_img">
                  <Icon type="ios-add" :size="50" />
                </div>
                <div class="dingding-img" v-else>
                  <img :src="formItem.ai_bg_img" width="300"   />
                </div>
                <div slot="tip" class="el-upload__tip">上传尺寸：宽1920px*高1080px</div>
              </el-upload>
            </el-form-item>
<!--  题库配置-->
          <el-form-item label="开启题库数据审核" v-if="setType == 2">
            <el-radio-group v-model="formItem.open_dataset_data_verify">
              <el-radio :label="'0'">否</el-radio>
              <el-radio :label="'1'">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开启题库分类定制" v-if="setType == 2">
            <el-radio-group v-model="formItem.open_dataset_category_config">
              <el-radio :label="'0'">否</el-radio>
              <el-radio :label="'1'">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开启题库题目定制" v-if="setType == 2">
            <el-radio-group v-model="formItem.open_dataset_data_config">
              <el-radio :label="'0'">否</el-radio>
              <el-radio :label="'1'">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开启用户隔离" v-if="setType == 2">
            <el-radio-group v-model="formItem.user_quarantine">
              <el-radio :label="'0'">否</el-radio>
              <el-radio :label="'1'">是</el-radio>
            </el-radio-group>
            <span style="margin-left:20px;">只显示自身组织用户</span>
          </el-form-item>
          <el-form-item label="开启认证题库" v-if="setType == 2">
            <el-radio-group v-model="formItem.open_cert_module">
              <el-radio :label="'0'">是</el-radio>
              <el-radio :label="'1'">否</el-radio>
            </el-radio-group>
          </el-form-item>
            <el-form-item label="开启课程模块" v-if="setType == 2">
              <el-radio-group v-model="formItem.open_course_module">
                <el-radio :label="'0'">是</el-radio>
                <el-radio :label="'1'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开启考试模块" v-if="setType == 2">
              <el-radio-group v-model="formItem.open_examine_module">
                <el-radio :label="'0'">是</el-radio>
                <el-radio :label="'1'">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开启实验模块" v-if="setType == 2">
              <el-radio-group v-model="formItem.open_experiment_module">
                <el-radio :label="'0'">是</el-radio>
                <el-radio :label="'1'">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="开启教材题库" v-if="setType == 2">
            <el-radio-group v-model="formItem.open_book_module">
              <el-radio :label="'0'">是</el-radio>
              <el-radio :label="'1'">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="开启chatgpt" v-if="setType == 2">
            <el-radio-group v-model="formItem.open_chatgpt">
              <el-radio :label="'0'">是</el-radio>
              <el-radio :label="'1'">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="开启登录二维码" v-if="setType == 2">
            <el-radio-group v-model="formItem.login_show_qrcode">
              <el-radio :label="'0'">是</el-radio>
              <el-radio :label="'1'">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="设置默认题库" v-if="setType == 2">
            <el-input v-model.number="formItem.default_category_id" @input="numberFilter" placeholder="请输入默认题库"></el-input>
          </el-form-item>
        </el-form>
        </div>
    </el-drawer>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../components/deleteModal.vue';
import Cookies from "js-cookie";
import { MessageBox } from "element-ui"
export default {
  name: "role",
  data(){
    const validateCity = (rule, value, callback)=>{
      if (!this.formItem.cityId.length) {
        return callback(new Error('请选择地域'));
      }else{
        callback();
      }
    }
    return{
      // 编辑抽屉是否可见
      editDrawer:false,
      // 服务类型数组数据
      service_typesArr:[],
      Cookies:Cookies,
      page:1,
      pageSize:10,
      total:0,
      data: [],
      loading:false,
      keyword:'',
      level:"",
      levelsArr:[],
      columns:[],
      level_rule:"",
      siteColumns: [
        {
          title: 'ID',
          key: 'id',
          sortable:'custom',
          width:80,
          fixed:"left"
        },
        {
          title: this.$t('site_name'),
          key: 'name',
          minWidth:200,
          fixed:"left",
          render: (h, para) => {
            return util.tableColumnTooltip(h,para.row.name,{name:"site/detail",params:{id:para.row.id,tab:"user"}});
          }
        },
        {
          title: '地区',
          key: 'id',
          width:150,
          render: (h, para) => {
            let arr = [];
            if(para.row.province){
              arr.push(para.row.province)
            }
            if(para.row.city){
              arr.push(para.row.city)
            }
            return util.tableColumnTooltip(h,arr.length ? arr.join('-') : '');
          },
        },
        {
          title: '类型',
          key: 'status',
          align: 'center',
          width:100,
          render: (h, para) => {
            return h('div', {
            }, this.typesObj[para.row.type]);
          },
        },
        {
          title: '服务类型',
          key: 'status',
          align: 'center',
          width:100,
          render: (h, para) => {
            return h('div', {
            }, this.service_typesObj[para.row.service_type]);
          },
        },
        {
          title: '刷题量',
          key: 'train_count',
          sortable:'custom',
          align: 'center',
          width:100,
          render: (h, para) => {
            return h('div', {
            }, para.row.tranStatSite && para.row.tranStatSite.train_count);
          },
        },
        // {
        //   title: '跟进',
        //   key: 'last_follow_content',
        //   render: (h, para) => {
        //     return h('div', {
        //       style:{
        //         whiteSpace:'pre-wrap'
        //       }
        //     }, para.row.last_follow_content);
        //   },
        // },
        {
            title: this.$t('site_membercount'),
            key: 'user_count',
            align: 'center',
            width:100,
            render: (h, para) => {
                return h('div', {
                }, para.row.user_count);
            },
        },
        {
          title: '星级评分',
          key: 'star',
          width:110,
          align: 'center',
          renderHeader: (h, params) => {
            return h('div',[
              h('span', '星级评分' ),
              h('Tooltip', {
              }, [
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
                h('span', {
                  style: {
                    whiteSpace: 'normal',
                    wordBreak: 'break-all'
                  },
                  slot:'content',
                },'按照用户量、刷题量、活跃度等方式综合计算分数用于评定组织等级。'),
              ])
            ]);
          },
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          width:100,
          //sortable: 'custom',
          render: (h, para) => {
            return h('div', {
            }, this.statusObj[para.row.status]);
          },
        },
        {
            title: this.$t('user_created_at'),
            key: 'created_at',
            sortable:'custom',
            width:140,
            render: (h, para) => {
                if (para.row.created_at) {
                  return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'))
                }
            },
        },
        {
            title: this.$t('user_updated_at'),
            key: 'updated_at',
            width:140,
            sortable:'custom',
            render: (h, para) => {
                if (para.row.updated_at) {
                  return h('span', util.timeFormatter(new Date(+para.row.updated_at*1000), 'yyyy-MM-dd hh:mm'))
                }
            },
        },
        // {
        //     title: this.$t('site_last_login_time'),
        //     key: 'end_time',
        //     align: 'center',
        //     maxWidth: 150,
        //     sortable: 'custom',
        //     render: (h, para) => {
        //         return h('span',
        //             util.timeFormatter(
        //                 new Date(+para.row.last_login_time * 1000),
        //                 'yyyy-MM-dd hh:mm'
        //             )
        //         );
        //     }
        // },
        {
          title: this.$t('site_operation'),
          width: 250,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h("el-button",{
                props:{
                  size:"small"
                },
                on:{
                  click:()=>{
                    this.showRemark(params.row);
                  }
                }
              },"跟进"),
              h("el-button",{
                props:{
                  size:"small",
                  type:"warning"
                },
                on:{
                  click:()=>{
                    this.edit(params.row);
                  }
                }
              },this.$t('system_edit')),
              // h('Button', {
              //   props: {
              //     type: 'info',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.toInvitation(params.row);
              //     }
              //   }
              // }, this.$t('site_list_op_invitation'))
              h("el-select",{
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:""
                },
                style:{
                  width:"75px",
                  marginLeft:"10px"
                },
                on:{
                  change:(event)=>{
                    console.log(event);
                    if(event==="禁用" || event==="激活"){
                      if(params.row.status == 1){
                        this.disableFn(params.row);
                      }else{
                        this.changeStatus(params.row)
                      }
                    }else if(event===this.$t('system_dele')){
                      this.delete(params.row);
                    }else if(event==="置顶"){
                      this.setTop(params.row);
                    }else if(event==="取消置顶"){
                      this.cancelTop(params.row);
                    }
                  }
                }
              },[
                params.row.is_top == 0  ? h('el-option', {
                  props: {
                    value: '置顶'
                  },
                }, '置顶') : h('el-option', {
                  props: {
                    value: '取消置顶'
                  }
                }, '取消置顶'),
                h('el-option', {
                  props: {
                    type: params.row.status == 1 ? 'danger' : 'success',
                    size: 'mini',
                    value:params.row.status == 1 ? '禁用' : '激活'
                  },
                  style:{
                    color:params.row.status == 1 ? '#F56C6C' : '#67C23A'
                  }
                }, params.row.status == 1 ? '禁用' : '激活' ),
                h("el-option",{
                  props:{
                    value:this.$t('system_dele')
                  },
                  style:{
                    color:"#F56C6C"
                  }
                },this.$t('system_dele'))
              ])
            ]);
          }
        }
      ],
      schoolColumns: [
        {
          title: 'ID',
          key: 'id',
          sortable:'custom',
          width:80,
          fixed:"left"
        },
        {
          title: this.$t('site_name'),
          key: 'name',
          minWidth:200,
          render: (h, para) => {
            // let texts=para.row.name;
            // if(texts.length>10){
            //   texts=texts.substring(0,10)+"...";
            // }

            return h("Tooltip",{
              props:{
                placement:"bottom-start",
                transfer:true,
              },
              style:{
                color:'#2db7f5',
                display:"block",
                width:"100%",
              }
            },[
              h('router-link',{
                props: {
                  to: {
                    name: 'site/detail',
                    params: {
                      id: para.row.id,
                      tab: 'user'
                    }
                  }
                },
                style:{
                  color:'#2db7f5',
                  display:"block",
                  width:"100%",
                  textOverflow:"ellipsis",
                  overflow:"hidden",
                  whiteSpace:"nowrap"
                }
              },para.row.name),
              h("span",{
                slot:"content",
                style:{
                  wordBreak:"break-all",
                  whiteSpace:"normal"
                }
              },para.row.name)
            ]);
          }
        },
        {
          title: '地区',
          key: 'id',
          minWidth:150,
          render: (h, para) => {
            let arr = [];
            if(para.row.province){
              arr.push(para.row.province)
            }
            if(para.row.city){
              arr.push(para.row.city)
            }
            return h('div', {
            }, arr.length ? arr.join('-') : '');
          },
        },
        {
          title: '类型',
          key: 'status',
          align: 'center',
          minWidth:100,
          render: (h, para) => {
            return h('div', {
            }, this.typesObj[para.row.type]);
          },
        },
        {
          title: '服务类型',
          key: 'status',
          align: 'center',
          minWidth:100,
          render: (h, para) => {
            return h('div', {
            }, this.service_typesObj[para.row.service_type]);
          },
        },
        {
          title: '刷题量',
          key: 'train_count',
          sortable:'custom',
          align: 'center',
          minWidth:100,
          render: (h, para) => {
            return h('div', {
            }, para.row.tranStatSite && para.row.tranStatSite.train_count);
          },
        },
        // {
        //   title: '跟进',
        //   key: 'last_follow_content',
        // },
        {
          title: '老师数量(评分)',
          key: 'teacher_count',
          minWidth:140,
          renderHeader: (h, params) => {
            return h('div',[
              h('span', '老师数量(评分)' ),
              h('Tooltip', {
              }, [
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
                h('span', {
                  style: {
                    whiteSpace: 'normal',
                    wordBreak: 'break-all'
                  },
                  slot:'content',
                },'按照用户量、刷题量、活跃度等方式综合计算分数用于评定组织等级。'),
              ])
            ]);
          },
          render: (h, para) => {
            return h('div', {
            }, para.row.teacher_count + ' ( ' + para.row.teacher_star + ' )');
          },
        },
        {
          title: '学生数量(评分)',
          key: 'student_count',
          minWidth:140,
          renderHeader: (h, params) => {
            return h('div',[
              h('span', '学生数量(评分)' ),
              h('Tooltip', {
              }, [
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
                h('span', {
                  style: {
                    whiteSpace: 'normal',
                    wordBreak: 'break-all'
                  },
                  slot:'content',
                },'按照用户量、刷题量、活跃度等方式综合计算分数用于评定组织等级。'),
              ])
            ]);
          },
          render: (h, para) => {
            return h('div', {
            }, para.row.student_count + ' ( ' + para.row.student_star + ' )');
          },
        },
        // {
        //   title: this.$t('site_membercount'),
        //   key: 'user_count',
        //   align: 'center',
        //   //maxWidth: 180,
        //   //sortable: 'custom',
        //   render: (h, para) => {
        //     return h('div', {
        //     }, para.row.user_count);
        //   },
        // },
        {
          title: '星级评分',
          key: 'star',
          minWidth:110,
          renderHeader: (h, params) => {
            return h('div',[
              h('span', '星级评分' ),
              h('Tooltip', {
              }, [
                h('Icon', {
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top'
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
                h('span', {
                  style: {
                    whiteSpace: 'normal',
                    wordBreak: 'break-all'
                  },
                  slot:'content',
                },'按照用户量、刷题量、活跃度等方式综合计算分数用于评定组织等级。'),
              ])
            ]);
          },
        },
        {
          title:"院校等级",
          key:"level",
          align:"center",
          minWidth:110,
          renderHeader:(h)=>{
            return h("div",[
              h("span","院校等级"),
              h("Tooltip",{
              },[
                h("Icon",{
                  style: {
                    marginLeft: '3px',
                    marginTop: '1px',
                    verticalAlign: 'top',
                  },
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                }),
                h('div', {
                  style: {
                    whiteSpace: 'normal',
                    wordBreak: 'break-all',
                    width:"auto"
                  },
                  slot:'content',
                },this.level_rule),
              ])
            ])
          }
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          minWidth:100,
          render: (h, para) => {
            return h('div', {
            }, this.statusObj[para.row.status]);
          },
        },
        // {
        //   title: this.$t('user_created_at'),
        //   key: 'created_at',
        //   sortable:'custom',
        //   render: (h, para) => {
        //     if (para.row.created_at) {
        //       return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'))
        //     }
        //   },
        // },
        {
          title: this.$t('user_updated_at'),
          key: 'updated_at',
          sortable:'custom',
          minWidth:140,
          render: (h, para) => {
            if (para.row.updated_at) {
              return h('span', util.timeFormatter(new Date(+para.row.updated_at*1000), 'yyyy-MM-dd hh:mm'))
            }
          },
        },
        // {
        //     title: this.$t('site_last_login_time'),
        //     key: 'end_time',
        //     align: 'center',
        //     maxWidth: 150,
        //     sortable: 'custom',
        //     render: (h, para) => {
        //         return h('span',
        //             util.timeFormatter(
        //                 new Date(+para.row.last_login_time * 1000),
        //                 'yyyy-MM-dd hh:mm'
        //             )
        //         );
        //     }
        // },
        {
          title: this.$t('site_operation'),
          width: 250,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h("el-button",{
                props:{
                  size:"small"
                },
                on:{
                  click:()=>{
                    this.showRemark(params.row);
                  }
                }
              },"跟进"),
              h("el-button",{
                props:{
                  type:"warning",
                  size:"small"
                },
                on:{
                  click:()=>{
                    this.edit(params.row);
                  }
                }
              },this.$t('system_edit')),
              // h('Button', {
              //   props: {
              //     type: 'info',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.toInvitation(params.row);
              //     }
              //   }
              // }, this.$t('site_list_op_invitation'))
              h("el-select",{
                props:{
                  transfer: true,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:'',
                },
                style:{
                  width:'80px',
                  marginLeft:'5px'
                },
                on:{
                  change:(event)=>{
                    if(event==="禁用" || event==="激活"){
                      if(params.row.status == 1){
                        this.disableFn(params.row);
                      }else{
                        this.changeStatus(params.row)
                      }
                    }else if(event===this.$t('system_dele')){
                      this.delete(params.row);
                    }else if(event==="置顶"){
                      this.setTop(params.row);
                    }else if(event==="取消置顶"){
                      this.cancelTop(params.row);
                    }
                  }
                }
              },[
                params.row.is_top == 0  ? h('el-option', {
                  props: {
                    value:"置顶"
                  },
                },"置顶") : h('el-option', {
                  props: {
                    value:"取消置顶"
                  }
                },"取消置顶"),
                h('el-option', {
                  props: {
                    value: params.row.status == 1 ? '禁用' : '激活',
                  },
                  style:{
                    color:params.row.status == 1 ? '#F56C6C' : '#67C23A'
                  }
                }, params.row.status == 1 ? '禁用' : '激活' ),
                h("el-option",{
                  props:{
                    value:this.$t('system_dele')
                  },
                  style:{
                    color:"#F56C6C"
                  }
                },this.$t('system_dele'))
              ])
            ]);
          }
        }
      ],
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      modalStatus:false,
      formItem:{
        id:0,
        name:'',
        desc:'',
        status:'',
        type:'0',
        cityId:[],
        self_domain:'',
        full_name:'',
        intro:'',
        logo:'',
        fullLogo:'',
        background_img:'',
        // 服务类型
        service_type:"",
        // 开启题库数据审核
        open_dataset_data_verify:"",
        // 开启题库分类定制
        open_dataset_category_config:"",
        // 开启题库题目定制
        open_dataset_data_config:"",
        // 开启用户隔离,只显示自身组织用户
        user_quarantine:"",
        // 设置默认题库
        default_category_id:"0",
        // 开启认证题库
        open_cert_module:"0",
        // 开启教材题库
        open_book_module:"0",
        open_chatgpt:"0",
        login_show_qrcode:"0",
        //AI配置项
        ai_domain:"",
        ai_logo:"",
        ai_title:"",
        ai_intro:"",
        ai_dialog_logo:"",
        ai_icon_logo:"",
        ai_bg_video:"",
        ai_bg_img:"",
        open_course_module:"0",
        open_examine_module:"0",
        open_experiment_module:"0"
      },
      statusList:[],
      statusObj:{},
      ruleValidate: {
        name: [
          { required: true, message: this.$t('site_add_name_tip'), trigger: 'blur' }
        ],
        // desc: [
        //   { required: false, message: this.$t('site_add_desc_tip'), trigger: 'blur' },
        // ],
        cityId:[
          { required: true, message: '请选择地域', validator: validateCity, },
        ],
        self_domain:[
          { required: false, message: '请输入二级域名', trigger: 'blur' },
        ],
        full_name:[
          { required: false, message: '请输入门户名称', trigger: 'blur' },
        ],
        intro:[
          { required: false, message: '请输入简介', trigger: 'blur' },
        ],
        logo:[
          { required: false, message: '请上传导航小LOGO', trigger: 'change' },
        ],
        fullLogo:[
          { required: false, message: '请上传门户长LOGO', trigger: 'change' },
        ],
        background_img:[
          { required: false, message: '请上传门户大背景', trigger: 'change' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      // siteStatus:'',
      // statusList:[
      //   {
      //     label:'全部',
      //     value:1,
      //   },
      //   {
      //     label:'已激活',
      //     value:2,
      //   },
      //   {
      //     label:'未激活',
      //     value:3,
      //   },
      //   {
      //     label:'已禁用',
      //     value:4,
      //   },
      // ],
      // siteId:'',
      // siteName:'',
      // siteNickname:'',
      // siteCompany:'',
      // searchParams:{},
      typesObj:{},
      service_typesObj:{},
      orderby:'',
      sort:'',
      recentlyAddedTypes:{},
      curRecentlyAddedTypeId:'-1',
      isValid:false,
      cityId: [],
      cityList: [],
      tabType:-1,
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly:true
      },
      modalOptionProps:{
        value: "id",
        label: "name",
        children: "children",
      },
      coverList:[],
      cover:'-1',
      curData:{},
      deleText:'',
      remarkModal:false,
      content:'',
      remarkList:[],
      remarkLoading:false,
      userList:[],
      userList2:[],
      userLoading:false,
      userId:'',

      editRemarkshow:false,
      editRemarkForm:{
        content:"",
        id:"",
        followup_user_id:""
      },
      setType:'0'
    }
  },
  components:{
    DeleModal
  },
  mounted(){

    if(this.$route.query.placeId){
      this.cityId = [this.$route.query.placeId || ''];
      this.tabType = 0;
    }
    if(this.tabType == 0){
      this.columns = this.schoolColumns;
    }else{
      this.columns = this.siteColumns;
    }
    this.getList();
    this.getPlaceList();
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    }
  },
  methods:{
    reset(){  //重置
      this.keyword = '';
      this.level="";
      this.curRecentlyAddedTypeId = '-1';
      this.isValid = false;
      this.cityId = [];
      this.page = 1;
      this.getList();
    },
    changeTab(id){
      this.tabType = id;
      if(this.tabType == 0){
        this.columns = this.schoolColumns;
      }else{
        this.columns = this.siteColumns;
        this.cover = '-1';
      }
      this.page = 1;
      this.getList();
    },
    changeSearch(data,name){
      this[name] = data;
      this.page = 1;
      this.getList();
    },
    sortChange(e){
      if(e.order == 'asc' || e.order == 'desc'){
        this.sort = e.order;
        this.orderby = e.key;
      }else{
        this.sort = '';
        this.orderby = '';
      }
      this.getList();
    },
    changeKeyword () {
      this.page = 1;
      this.getList();
    },
    // 过滤非数字
    numberFilter(e){
      const reg=/\D/ig;
      const value=e.replace(reg,"");
      this.formItem.default_category_id=value;
    },
    add(){
      this.modalTit = this.$t('site_add');
      this.type = '';
      this.formItem = {
        id:0,
        name:'',
        type:'0',
        cityId:[],
        self_domain:'',
        full_name:'',
        intro:'',
        logo:'',
        fullLogo: '',
        background_img:'',
        // 服务类型
        service_type:"0",
        // 开启题库数据审核
        open_dataset_data_verify:"0",
        // 开启题库分类定制
        open_dataset_category_config:"0",
        // 开启题库题目定制
        open_dataset_data_config:"0",
        // 开启用户隔离,只显示自身组织用户
        user_quarantine:"0",
        // 设置默认题库
        default_category_id:"0",
        // 开启认证题库
        open_cert_module:"0",
        // 开启教材题库
        open_book_module:"0",
        // 开启gpt
        open_chatgpt:"0",
        login_show_qrcode:"0",
        open_course_module:"0",
        open_examine_module:"0",
        open_experiment_module:"0"
      };
      this.editDrawer=true;
      // this.modalStatus = true;
    },
    check(data){
      this.modalTit = this.$t('site_check');
      this.type = 'check';
      let arr = [];
      if(data.province_id){
        arr.push(data.province_id)
        if(data.city_id){
          arr.push(data.city_id)
        }
      }
      this.formItem = {
        id:0,
        name:data.name,
        desc:data.intro,
        type: data.type,
        cityId:arr,
        self_domain:data.self_domain,
        full_name:data.full_name,
        intro:data.intro,
        logo:data.logo,
        fullLogo: data.full_logo,
        background_img:data.background_img,
        // 服务类型
        service_type:data.service_type,
        // 开启题库数据审核
        open_dataset_data_verify:data.open_dataset_data_verify,
        // 开启题库分类定制
        open_dataset_category_config:data.open_dataset_category_config,
        // 开启题库题目定制
        open_dataset_data_config:data.open_dataset_data_config,
        // 开启用户隔离,只显示自身组织用户
        user_quarantine:data.user_quarantine,
        // 设置默认题库
        default_category_id:data.default_category_id,
        login_show_qrcode:data.login_show_qrcode,
        ai_domain:data.ai_domain,
        ai_logo:data.ai_logo,
        ai_title:data.ai_title,
        ai_intro:data.ai_intro,
        ai_dialog_logo:data.ai_dialog_logo,
        ai_icon_logo:data.ai_icon_logo,
        ai_bg_video:data.ai_bg_video,
        ai_bg_img:data.ai_bg_img,
        open_course_module:data.open_course_module,
        open_examine_module:data.open_examine_module,
        open_experiment_module:data.open_experiment_module
      };
      this.modalStatus = true;
    },
    edit(data){
      this.modalTit = '编辑';
      this.type = 'edit';
      let arr = [];
      if(data.province_id > 0){
        arr.push(data.province_id)
        if(data.city_id > 0){
          arr.push(data.city_id)
        }
      }
      this.formItem = {
        id:data.id,
        name:data.name,
        desc:data.intro,
        type: data.type,
        cityId:arr,
        self_domain:data.self_domain,
        full_name:data.full_name,
        intro:data.intro,
        logo:data.logo,
        fullLogo: data.full_logo,
        background_img:data.background_img,
        // 服务类型
        service_type:data.service_type,
        // 开启题库数据审核
        open_dataset_data_verify:data.open_dataset_data_verify,
        // 开启题库分类定制
        open_dataset_category_config:data.open_dataset_category_config,
        // 开启题库题目定制
        open_dataset_data_config:data.open_dataset_data_config,
        // 开启用户隔离,只显示自身组织用户
        user_quarantine:data.user_quarantine,
        // 设置默认题库
        default_category_id:data.default_category_id,
        open_cert_module:data.open_cert_module,
        open_book_module:data.open_book_module,
        open_chatgpt:data.open_chatgpt,
        login_show_qrcode:data.login_show_qrcode,
        ai_domain:data.ai_domain,
        ai_logo:data.ai_logo,
        ai_title:data.ai_title,
        ai_intro:data.ai_intro,
        ai_dialog_logo:data.ai_dialog_logo,
        ai_icon_logo:data.ai_icon_logo,
        ai_bg_video:data.ai_bg_video,
        ai_bg_img:data.ai_bg_img,
        open_course_module:data.open_course_module,
        open_examine_module:data.open_examine_module,
        open_experiment_module:data.open_experiment_module
      };
      // this.modalStatus = true;
      this.editDrawer=true;
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
        orderby:this.orderby,
        sort:this.sort,
        recently_added_type:this.curRecentlyAddedTypeId,
        type:this.tabType,
        is_valid:this.isValid,
        province:this.cityId[0],
        city:this.cityId[1],
        is_cover:this.cover,
        is_with_last_follow:1,
        level:this.level
      };

      this.api.site.siteList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
        this.statusObj = res.statuses;
        const levels=res.levels;
        this.levelsArr=[];
        Object.keys(levels).forEach(key=>{
          this.levelsArr.push({
            value:levels[key],
            label:levels[key]
          });
        });
        this.typesObj = res.types;
        this.service_typesObj = res.service_types;
        this.level_rule=res.level_rule;
        // 将服务类型对象转为数组
        this.service_typesArr=[];
        Object.keys(this.service_typesObj).forEach(key=>{
          this.service_typesArr.push({
            value:key,
            label:this.service_typesObj[key]
          });
        });
        this.statusList = [];
        for(let name in res.statuses){
          this.statusList.push({
            label:res.statuses[name],
            value:name,
          })
        }
        this.recentlyAddedTypes = [{
          label:'全部',
          value:'-1'
        }];
        for(let name in res.recently_added_types){
          this.recentlyAddedTypes.push({
            label:res.recently_added_types[name],
            value:name,
          })
        }
        this.coverList = [{
          label:'全部',
          value:'-1'
        }];
        for(let name in res.covers){
          this.coverList.push({
            label:res.covers[name],
            value:name,
          })
        }
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
      this.deleteModalType = 'delete';
      this.deleText = '确定删除吗？';
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      if(this.deleteModalType =='delete'){
        let data = {
          name:this.curDeleData.name,
          site_id:this.curDeleData.id,
        }
        this.modalLoading = true;
        this.api.site.siteDelete(data).then((res)=>{
          this.modalLoading = false;
          this.deleModalStatus = false;
          this.getList();
        }).catch((err)=>{
          this.modalLoading = false;
        });
      }else if( this.deleteModalType == 'disable'){
        let params = {
          site_id:this.curData.id,
        }
        this.modalLoading = true;
        this.api.site.siteClose(params).then((res)=>{
          this.modalLoading = false;
          this.deleModalStatus = false;
          this.getList();
        }).catch((err)=>{
          this.modalLoading = false;
        })
      }

    },
    disableFn(data){
      this.curData = data;
      this.deleModalStatus = true;
      this.deleteModalType = 'disable';
      this.deleText = '确定禁用吗？';
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    // 编辑按钮确定事件
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            site_id:this.formItem.id,
            name:this.formItem.name,
            // intro:this.formItem.desc,
            type:this.formItem.type,
            province_id:this.formItem.cityId[0],
            city_id:this.formItem.cityId[1],
            self_domain:this.formItem.self_domain,
            full_name:this.formItem.full_name,
            intro:this.formItem.intro,
            logo:this.formItem.logo,
            full_logo:this.formItem.fullLogo,
            background_img:this.formItem.background_img,
            // 服务类型
            service_type:this.formItem.service_type,
            // 开启题库数据审核
            open_dataset_data_verify:this.formItem.open_dataset_data_verify,
            // 开启题库分类定制
            open_dataset_category_config:this.formItem.open_dataset_category_config,
            // 开启题库题目定制
            open_dataset_data_config:this.formItem.open_dataset_data_config,
            // 开启用户隔离,只显示自身组织用户
            user_quarantine:this.formItem.user_quarantine,
            // 设置默认题库
            default_category_id:this.formItem.default_category_id,
            open_cert_module:this.formItem.open_cert_module,
            open_book_module:this.formItem.open_book_module,
            open_chatgpt:this.formItem.open_chatgpt,
            login_show_qrcode:this.formItem.login_show_qrcode,
            ai_domain:this.formItem.ai_domain,
            ai_logo:this.formItem.ai_logo,
            ai_title:this.formItem.ai_title,
            ai_intro:this.formItem.ai_intro,
            ai_dialog_logo:this.formItem.ai_dialog_logo,
            ai_icon_logo:this.formItem.ai_icon_logo,
            ai_bg_video:this.formItem.ai_bg_video,
            ai_bg_img:this.formItem.ai_bg_img,
            open_course_module:this.formItem.open_course_module,
            open_examine_module:this.formItem.open_examine_module,
            open_experiment_module:this.formItem.open_experiment_module,
          };
          // 按钮加载样式
          this.addBtnLoading = true;
          // 如果有id发起请求
          if(this.formItem.id){
            this.api.site.siteUpdate(data).then(()=>{
              this.addBtnLoading = false;
              // this.modalStatus = false;
              this.editDrawer=false;
              this.getList();
            }).catch(()=>{
              this.addBtnLoading = false;
            })
          }else{
            // 没有id是添加
            this.api.site.siteCreate(data).then(()=>{
              this.addBtnLoading = false;
              // this.modalStatus = false;
              this.editDrawer=false;
              this.page = 1;
              this.getList();
            }).catch(()=>{
              this.addBtnLoading = false;
            })
          }

        }else{
          console.log(this.modalStatus)
        }
      })
    },
    // 编辑取消按钮事件
    cancel(){
      // this.modalStatus = false;
      this.editDrawer = false;
      this.$refs.formCustom.resetFields();
    },
    toInvitation(){
      this.$router.push({
        path:'/site/invitation',
        query:{

        }
      })
    },
    changeStatus(data){ //改变状态
      let params = {
        site_id:data.id,
      }
      if(data.status == 1){ //禁用
        this.api.site.siteClose(params).then((res)=>{
          this.getList();
        })
      }else{  //激活
        this.api.site.siteOpen(params).then((res)=>{
          this.getList();
        })
      }
    },
    setTop(data){ //置顶
      let params = {
        site_id:data.id,
        is_top:1,
      };
      this.api.site.siteUpdate(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      })
    },
    cancelTop(data){  //取消置顶
      let params = {
        site_id:data.id,
        is_top:0,
      };
      this.api.site.siteUpdate(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      })
    },
    getPlaceList(){ //获取地域
      this.api.site.placeList().then((res)=>{
        this.cityList = res.list;
      })
    },
    showRemark(data){
      this.curData = data;
      this.content = '';
      this.userId = '';
      this.remarkList = [];
      this.remarkModal = true;
      this.getRemarkList();
    },
    getRemarkList(){  //获取评论列表
      let params = {
        followup_site_id:this.curData.id
      };
      this.remarkLoading = true;
      this.api.customer.customerFollowList(params).then((res)=>{
        this.remarkLoading = false;
        this.remarkList = res.list;
        this.remarkList.forEach((item)=>{
          item.created_at = util.timeFormatter(new Date(+item.created_at*1000), 'yyyy-MM-dd hh:mm');
        })
      }).catch((err)=>{
        this.remarkLoading = false;
      })
    },
    confirmRemark(){
      let params = {
        followup_site_id:this.curData.id,
        content:this.content,
        followup_user_id:this.userId
      };
      this.content = '';
      this.api.customer.customerFollowCreate(params).then((res)=>{
        this.$Message.success('操作成功');
        this.userId = '';
        this.getRemarkList();
      })
    },
    cancelRemark(){
      this.remarkLoading = false;
      this.getList();
    },
    remoteMethod(query){
      this.getUserList(query)
    },
    getUserList(query) {
      let params = {
        pageSize: 10,
        page: 1,
        keyword: query,
        site_id:this.curData.id
      };
      this.userLoading = true;
      this.api.production.userList(params).then((res) => {
        this.userLoading = false;
        this.userList = res.list;
        this.userList2=res.list;
      }).catch((err)=>{
        this.userLoading = false;
      })
    },
    // uploadFileSuccess(response,file, fileList){  //上传文件成功
    //   this.formValidate.imgUploadfileid = response.data.info.id;
    //   this.uploadImg = response.data.info.upload_path;
    //   this.$refs.formValidate.validateField('imgUploadfileid');
    // },
    uploadLogoSuccess(response,file, fileList){
      // this.formValidate.imgUploadfileid = response.data.info.id;
      this.formItem.logo = response.data.info.upload_path;
      this.$refs.formCustom.validateField('logo');
    },
    uploadFullLogoSuccess(response,file, fileList){
      this.formItem.fullLogo = response.data.info.upload_path;
      this.$refs.formCustom.validateField('fullLogo');
    },
    uploadBackgroundSuccess(response,file, fileList){
      this.formItem.background_img = response.data.info.upload_path;
      this.$refs.formCustom.validateField('background_img');
    },


    uploadAiLogoSuccess(response,file, fileList){
      // this.formValidate.imgUploadfileid = response.data.info.id;
      this.formItem.ai_logo = response.data.info.upload_path;
      this.$refs.formCustom.validateField('ai_logo');
    },
    uploadAiDialogLogoSuccess(response,file, fileList){
      this.formItem.ai_dialog_logo = response.data.info.upload_path;
      this.$refs.formCustom.validateField('ai_dialog_logo');
    },
    uploadAiIconLogoSuccess(response,file, fileList){
      this.formItem.ai_icon_logo = response.data.info.upload_path;
      this.$refs.formCustom.validateField('ai_icon_logo');
    },
    uploadAiBgVideoSuccess(response,file, fileList){
      this.formItem.ai_bg_video = response.data.info.upload_path;
      this.$refs.formCustom.validateField('ai_bg_video');
    },
    uploadAiBgImgSuccess(response,file, fileList){
      this.formItem.ai_bg_img = response.data.info.upload_path;
      this.$refs.formCustom.validateField('ai_bg_img');
    },

    editRemark(item){
      this.editRemarkForm.id=item.id;
      this.editRemarkForm.content=item.content;
      this.editRemarkForm.followup_user_id=item?.user?.id || "";
      this.userList2.push({
        id:item?.user?.id||"",
        nickname:item?.user?.nickname || ""
      });
      this.editRemarkshow=true;
    },
    editRemarkOk(){
      this.api.user.updateFollow(this.editRemarkForm).then(()=>{
        this.editRemarkshow=false;
        this.getRemarkList();
        this.$Message.success("编辑成功");
      });
    },
    editRemarkClose(){
      this.editRemarkForm={
        content:"",
        id:"",
        followup_user_id:""
      }
      this.userList2=[];
    },
    deleteRemark(item){
      MessageBox.confirm('该操作不可逆是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.user.deleteFollow({
          id: item.id
        }).then(() => {
          this.getRemarkList();
          this.$Message.success("删除成功");
        });
      });
    },
    deleFullLogo(){
      this.formItem.fullLogo = '';
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .site-tab{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    height: 40px;

    .site-tab-l{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      font-size: 16px;
      color: #666666;
      >p{
        padding-top: 6px;
        margin-right: 28px;
        height: 100%;
        cursor: pointer;
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;;
      }
    }
  }

  .search{
    //margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        margin-right: 10px;
        width: 50px;
        text-align: right;
      }
      >button{
        margin-right: 20px;
      }
    }
  }
  .search{
    margin-top: 20px;
    //margin-bottom: 20px;
    font-size: 14px;
    color:#333333;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        text-align: right;
        font-weight: bold;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 0 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            font-weight: bold;
            color: #4A79FF;
          }
        }
      }
    }
  }

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.top-btn{
  display: flex;
  justify-content: flex-end;
}
.form-item-label{
  font-size: 14px;
  color: #515a6e;
  font-weight: bold;
  margin-bottom: 20px;
}
.history{
  font-size: 14px;
  .history-tit{
    margin: 20px 0;
    font-weight: bold;
  }
  .history-list{
    max-height: 500px;
    overflow-y: auto;
  }
  .time{
    font-size: 14px;
    margin-bottom: 10px;
    >span{
      padding: 0 20px;
    }
  }
  .content-wrap{
    display: flex;
    justify-content: space-between;

    .content{
      font-size: 14px;
      line-height: 26px;
      white-space: pre-wrap;
    }
  }

}
.upload-btn{
  width: 120px;
  height: 120px;
  border: 1px solid #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-logo{
  position: relative;
  >img{
    width:120px;
    height: 120px;
  }
  .full-logo-dele{
    display: none;

  }
}
.full-logo:hover .full-logo-dele{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color:#FFFFFF;
}
</style>
<style>
.el-cascader-menu{
  height: 200px !important;
}

/*.cont .el-radio__inner {*/
/*  border-radius: 0;*/
/*  border: 0;*/
/*  width: 170px;*/
/*  height: 34px;*/
/*  background-color: transparent;*/
/*  cursor: pointer;*/
/*  box-sizing: border-box;*/
/*  position: absolute;*/
/*  top: -18px;*/
/*  left: -19px;*/
/*}*/

/*.cont .el-radio__input.is-checked .el-radio__inner {*/
/*  background: transparent;*/
/*}*/
</style>
